* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
}

@font-face {
  src: url('../fonts/Gunar.ttf');
  font-family: gunar;
}

@font-face {
  src: url('../fonts/GunarBold.ttf');
  font-family: gunar-bold;
}

@import './home.scss';
