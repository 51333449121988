.home {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1% 0%;

  .logo {
    width: 10%;
  }

  .desc {
    font-family: gunar-bold;
    font-size: 1vw;
    color: white;
    text-align: center;
  }

  .button {
    width: 10%;
    height: 15%;
    background: url(../images/btn_mainsite_pc_normal.png);
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
      background: url(../images/btn_mainsite_pc_hover.png);
      background-size: 100% 100%;
    }
  }
}

.landscape {
  background: url(../images/BG_landscape.jpg);
  background-size: 100% 100%;
}

.potrait {
  background: url(../images/BG_portrait.jpg);
  background-size: 100% 100%;
}

.content-landscape {
  background: url(../images/BG_landscape.png);
  background-size: 100% 100%;
}

.content-potrait {
  background: url(../images/BG_portrait.png);
  background-size: 100% 100%;

  .logo {
    width: 30%;
  }

  .desc {
    font-size: 3vw;
  }

  .button {
    width: 32.5%;
    height: 12.5%;
  }
}
